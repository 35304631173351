import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import AdminVoteWidget from './widgets/AdminVoteWidget';
import UserVoteWidget from './widgets/UserVoteWidget';
import BackendURLContext from '../../contexts/BackendURLContext';

function GetWidget(data){
    // console.log(data);  
    if (!data) return ()=><h1>Загрузка</h1>;
    
    if (!data.status) return ()=><h1>Ошибка</h1>;
    const status = data.status.split('_');
    if (status[0] === "ADMIN") return ()=><AdminVoteWidget data={data}/>;
    if (status[0] === "USER") return ()=><UserVoteWidget data={data}/>;
    return ()=><h1>Ошибка</h1>;
}


function PollPage(){
    const {poll_id, url} = useParams();
    const [data, setData] = useState(null);
    const backend_url = useContext(BackendURLContext);

    useEffect(()=>{
        fetch(`${backend_url}/${poll_id}/${url}`)
            .then(x=>x.json())
            .then(x=>setData(x))
    },[poll_id, url, backend_url]);

    let Widget = GetWidget(data);
    return(
        <Widget/>
    )
}

export default PollPage;