import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import BackendURLContext from "../contexts/BackendURLContext";

function AppPollMain(){
    const {poll_id} = useParams();
    const [data, setData] = useState(null);
    const backend_url = useContext(BackendURLContext);
    useEffect(()=>{
        fetch(`${backend_url}/${poll_id}/`)
            .then(x=>x.json())
            .then(x=>setData(x))
    },[poll_id, backend_url])
    return (
    <>
        {!data?<h1>Загрузка</h1>:<>
            {!data.time_end?
                <h1>Опрос ещё идет</h1>:<>

            <h1>{data.title}</h1>
            <h2>Результаты</h2>
            
            {Object.keys(data.results).map(i=><>
                <h3>{data.results[i].text}</h3>
                <Table striped bordered hover>
            <thead>
                <tr>
                <th>Вариант</th>
                <th>Количество проголосовавших</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(data.results[i].choices).map((j, id) => 
                <tr key={id}>
                    <td>{data.results[i].choices[j].text}</td>
                    <td>{data.results[i].choices[j].count}</td>
                </tr>
                )}
                
            </tbody>
        </Table>
                </>
            )}
            </>}
        </>}

    </>
    )
    
};

export default AppPollMain;