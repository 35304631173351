import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './screens/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AppCreate from './screens/AppCreate';
import Header from './components/Navbar/Header';
import { Container } from 'react-bootstrap';
import AppPoll from './screens/AppPoll';
import AppPollMain from './screens/AppPollMain';
import BackendURLContext from './contexts/BackendURLContext';
import CreateVotePage from './pages/CreateVotePage/CreateVotePage';
import PollPage from './pages/PollPage/PollPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/create",
    element: <CreateVotePage/>
    // element: <AppCreate/>,
  },
  {
    path: "/:poll_id",
    element: <AppPollMain/>
  },
  {
    path: "/:poll_id/:url",
    // element: <AppPoll/>
    element: <PollPage/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BackendURLContext.Provider value={"https://votes-dev.inp.nsk.su/api/v2"}>
      <Header/>
      <Container>
        <RouterProvider router={router} />
      </Container>
  </BackendURLContext.Provider>
  </React.StrictMode>
);

