import { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import QuestionEditor from "./features/QuestionEditor";
import parseParticipantsFile from "./utils/parseParticipantFile";
import ParticipantsTable from "./features/ParticipantsTable";
import BackendURLContext from "../../../../contexts/BackendURLContext";
import getCookie from "../../../../utils/getCookie";
import { useNavigate } from 'react-router-dom';


function CreateFormWidget(){
    const backendUrl = useContext(BackendURLContext);
    const navigate = useNavigate();
    const [createForm, setCreateForm] = useState({
        title: "",
        admin: "",
        creator: ""
    });
    const [participants, setParticipants] = useState([]);
    const [questions, setQuestions] = useState([{
        question: '',
        choices: [''],
        type: 'question',
        min: 1,
        max: 1,
    }]);

    const loadParticipants = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = e => {
            setParticipants(
                parseParticipantsFile(
                    e.target.result));
        }
        reader.readAsText(file);
    }
    
    const addParticipant = ()=>setParticipants(participants=>{
        const tmp = [...participants];
        tmp.push({name:'', email:''});
        return tmp;
    })

    const handleTitleChange = e => setCreateForm(
        form=>{return {...form, title: e.target.value};});
    const handleCreatorChange = e => setCreateForm(
        form=>{return {...form, creator: e.target.value};});
    const handleAdminChange = e => setCreateForm(
        form=>{return {...form, admin: e.target.value};});
    const handleSubmit = e => {
        e.preventDefault();
        const data = {
            title: createForm.title,
            admin: createForm.admin,
            creator: createForm.creator,
            participants: participants,
            questions: questions
        }
        const csrftoken = getCookie('csrftoken');
        fetch(`${backendUrl}/create`, {
            method: "POST",
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer", 
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                'X-CSRFToken': csrftoken,
            },
        }).then(x => {
            if (x.status === 200){
                alert('Голосование создано');
                return x.json();
            } else {
                alert('Ошибка');
            }
        }).catch(console.error).then(x=>navigate(`/${x.url}/${x.admin_url}`));
        console.log(createForm, participants, questions);
    }
    return (
        <Form onSubmit={handleSubmit} className="pb-5">
            <Form.Group className='form-floating mb-3' >
                <Form.Control required type="text"
                    placeholder=" " 
                    value={createForm.title}
                    onChange={handleTitleChange}
                    id='title'
                />
                <label htmlFor='title'>Тема опроса</label>
            </Form.Group>
            <Form.Group className='form-floating mb-3' >
                <Form.Control type="text"
                    placeholder=" " 
                    value={createForm.creator}
                    onChange={handleCreatorChange}
                    id='creator'
                />
                <label htmlFor='creator'>Создатель опроса</label>
            </Form.Group>

            <hr/>
            <QuestionEditor
                questions={questions}
                setQuestions={setQuestions}
            />
            
            <hr/>
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Label><b>Участники</b></Form.Label>
                <Form.Control type="file" 
                    accept=".txt"
                    onChange={loadParticipants}
                />
            </Form.Group>
            <ParticipantsTable
                participants={participants}
                setParticipants={setParticipants}
            />
            <Button variant='secondary' 
                onClick={addParticipant}
            >
                Добавить участника
            </Button>
            <hr />
            <Form.Group required className="mb-3" controlId="formAdmin">
                <Form.Label>Администратор</Form.Label>
                <Form.Control type="email" 
                placeholder="admin@admin.com" 
                required
                value={createForm.admin} 
                onChange={handleAdminChange}/>
            </Form.Group>
            <Button variant="primary" type="submit">
                Создать опрос
            </Button>
        </Form>
    )
}

export default CreateFormWidget;