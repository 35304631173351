

function App() {
  return (
    <div className="App">
      <h1>Сервис для голосования</h1>
      <p>С вашими предложениями/замечаниями обращайтесь к <a href="#">N.V.Okhotnikov@inp.nsk.su</a></p>
    </div>
  );
}

export default App;
