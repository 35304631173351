import { Button } from "react-bootstrap";



function AdminUserUrl({url}){
    const handleDoubleClick = (e)=>{
        navigator.clipboard.writeText(e.target.dataset.url);
        alert('Скопировано в буфер обмена');
    }
    return (
<>
    <Button onClick={handleDoubleClick} data-url={url}>Скопировать в буфер обмена</Button>
</>
    )

}

export default AdminUserUrl;