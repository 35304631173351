import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import { Button } from "react-bootstrap";
import AdminPage from "./AdminPage";
import UserPage from "./UserPage";
import BackendURLContext from "../contexts/BackendURLContext";


function AppPoll(props){
    const {poll_id, url} = useParams();
    const [data, setData] = useState(null);
    const backend_url = useContext(BackendURLContext);

    useEffect(()=>{
        fetch(`${backend_url}/${poll_id}/${url}`)
            .then(x=>x.json())
            .then(x=>setData(x))
    },[poll_id, url, backend_url]);
    return (
        <>
            {!data?<h1>Загрузка</h1>:
            <>
                <h1>{data.title}</h1>
                {data.is_admin?<AdminPage data={data}/>:<UserPage data={data}/>}
            </>}
        </>
    )

}

export default AppPoll;